import {Route, Routes} from "react-router-dom";
import {
    LazyAdmin,
    LazyBoard,
    LazyBoardComplex, LazyForbidden,
    LazyKiosk,
    LazyKioskComplex,
    LazyMain,
    LazyMyQueue,
    LazyNoMatch,
    LazyOperator,
    LazyTabloMini,
    LazyVrach
} from "./constantsRoutes";
import {Suspense} from "react";
import {ErrorBoundary} from "react-error-boundary";
import {LazySuspense} from "../Lazy/LazySuspense";
import {ErrorBoundaryFallBack} from "../Lazy/ErrorBoundary";
import {useIsAuth} from "../../core/utils/isAuth";
import {useAppSelector} from "../../core/redux/hooks";

export const PATH = {
    MAIN: "/",
    ADMIN: "/admin",
    USERS: "/users",
    ADMIN_QUEUE: "/admin-queue",
    ADMIN_QUEUE_MANAGE_QUEUES_PACKETS: "/admin-queue/manage-queues-packets",
    ANALYTICS: "/analytics",
    ANALYTICS_TODAY: "/analytics-today",
    SETTINGS_VIDEO: "/settings-video",
    INFO: "/info",
    BOARD_VIDEOS: "/board-videos",
    CHARTS: "/charts",
    KIOSK: "/kiosk",
    KIOSK_COMPLEX: "/kiosk-complex",
    BOARD: "/board",
    BOARD_COMPLEX: "/board-complex",
    OPERATOR: "/operator",
    TABLO_MINI: "/tablo-mini",
    VRACH: "/vrach",
    MY_QUEUE: "/my-queue",
    PRICES: "/prices",
    PRODUCT: "/product",
    RELOAD: "/reload",
    DOWNLOAD_LIST: "/download-list",
    CONTACTS: "/contacts",
    ERROR: "/error404",
    S_ADMIN: "/s-admin",
    S_ADMIN_ORGANIZATIONS: "/s-admin/organizations",
    S_ADMIN_USERS: "/s-admin/users",
    S_ADMIN_REQUESTS: "/s-admin/requests",
    FORBIDDEN: "/error403"
};

export const Router = () => {
    useIsAuth(!!localStorage.getItem("auth"));
    const auth = useAppSelector((state) => state.auth.isLogin);
    return (
        <ErrorBoundary fallback={<ErrorBoundaryFallBack />}>
            <Suspense fallback={<LazySuspense />}>
                <Routes>
                    <Route path={PATH.MAIN} element={<LazyMain />} />
                    <Route path={PATH.VRACH} element={<LazyVrach />} />
                    <Route path={PATH.KIOSK} element={<LazyKiosk />} />
                    <Route path={PATH.KIOSK_COMPLEX} element={<LazyKioskComplex />} />
                    <Route path={PATH.BOARD} element={<LazyBoard />} />
                    <Route path={PATH.BOARD_COMPLEX} element={<LazyBoardComplex />} />
                    <Route path={PATH.OPERATOR} element={<LazyOperator />} />
                    <Route path={PATH.TABLO_MINI} element={<LazyTabloMini />} />
                    <Route path={PATH.PRICES} element={<LazyMain />} />
                    <Route path={PATH.PRODUCT} element={<LazyMain />} />
                    <Route path={PATH.CONTACTS} element={<LazyMain />} />
                    <Route path={PATH.MY_QUEUE}>
                        <Route path={":id"} element={<LazyMyQueue />} />
                    </Route>

                    {auth && <Route path={PATH.ADMIN} element={<LazyAdmin />} />}
                    {auth && <Route path={PATH.ANALYTICS} element={<LazyAdmin />} />}
                    {auth && <Route path={PATH.ADMIN_QUEUE} element={<LazyAdmin />} />}
                    {auth && <Route path={PATH.ANALYTICS_TODAY} element={<LazyAdmin />} />}
                    {auth && (
                        <Route
                            path={PATH.ADMIN_QUEUE_MANAGE_QUEUES_PACKETS}
                            element={<LazyAdmin />}
                        />
                    )}
                    {auth && <Route path={PATH.SETTINGS_VIDEO} element={<LazyAdmin />} />}
                    {auth && <Route path={PATH.INFO} element={<LazyAdmin />} />}
                    {auth && <Route path={PATH.BOARD_VIDEOS} element={<LazyAdmin />} />}
                    {auth && <Route path={PATH.USERS} element={<LazyAdmin />} />}
                    {auth && <Route path={PATH.RELOAD} element={<LazyAdmin />} />}
                    {auth && <Route path={PATH.DOWNLOAD_LIST} element={<LazyAdmin />} />}
                    {auth && <Route path={PATH.S_ADMIN_ORGANIZATIONS} element={<LazyAdmin />} />}
                    {auth && <Route path={PATH.S_ADMIN_USERS} element={<LazyAdmin />} />}
                    {auth && <Route path={PATH.S_ADMIN_REQUESTS} element={<LazyAdmin />} />}

                    <Route path="*" element={<LazyNoMatch />} />
                    <Route path={PATH.FORBIDDEN} element={<LazyForbidden/>}/>
                </Routes>
            </Suspense>
        </ErrorBoundary>
    );
};
