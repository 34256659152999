import config from "../../config.json"

export const WEBSOCKET_SERVER = config.KSEServer.WebSocket.Ws;
export const WEBSOCKET_SERVER_PROTOCOL = config.KSEServer.WebSocket.Protocols;

export const HTTP_SERVER = config.KSEServer.Http.Url;
export const HTTP_SERVER_PROTOCOL = config.KSEServer.Http.Protocols;
export const END_POINT_GET_QUEUE = config.KSEServer.Http.EndPoints.EndPointGetQueues;
export const END_POINT_GET_QUEUE_LIST = config.KSEServer.Http.EndPoints.EndPointGetQueuesList;
export const END_POINT_GET_QUEUE_LIST_BY_QUEU = config.KSEServer.Http.EndPoints.EndPointGetQueuesListByQueu;
export const END_POINT_POST_QUEUE = config.KSEServer.Http.EndPoints.EndPointPostQueues;
export const END_POINT_POST_LOGO = config.KSEServer.Http.EndPoints.EndPointPostLogo;
export const END_POINT_UPDATE_QUEUE = config.KSEServer.Http.EndPoints.EndPointUpdateQueue;
export const END_POINT_DELETE_QUEUE = config.KSEServer.Http.EndPoints.EndPointDeleteQueues;
export const END_POINT_GET_QUEUE_COMPLEX = config.KSEServer.Http.EndPoints.EndPointGetQueuesComplex;
export const END_POINT_POST_QUEUE_COMPLEX = config.KSEServer.Http.EndPoints.EndPointPostQueuesComplex;
export const END_POINT_UPDATE_QUEUE_COMPLEX = config.KSEServer.Http.EndPoints.EndPointUpdateQueueComplex;
export const END_POINT_DELETE_QUEUE_COMPLEX = config.KSEServer.Http.EndPoints.EndPointDeleteQueuesComplex;
export const END_POINT_GET_SERVICES = config.KSEServer.Http.EndPoints.EndPointGetServices;
export const END_POINT_GET_USERS = config.KSEServer.Http.EndPoints.EndPointGetUsers;
export const END_POINT_GET_CLIENTS = config.KSEServer.Http.EndPoints.EndPointGetClients;
export const END_POINT_PUT_IS_ACTIVE_USER = config.KSEServer.Http.EndPoints.EndPointPutIsActiveUser;
export const END_POINT_GET_BOARD = config.KSEServer.Http.EndPoints.EndPointGetBoard;
export const END_POINT_PUT_IS_ACTIVE_BOARD = config.KSEServer.Http.EndPoints.EndPointPutIsActiveBoard;
export const END_POINT_GET_MINI_BOARD = config.KSEServer.Http.EndPoints.EndPointGetMiniBoard;
export const END_POINT_GET_KIOSK = config.KSEServer.Http.EndPoints.EndPointGetKiosk;
export const END_POINT_PUT_IS_ACTIVE_KIOSK = config.KSEServer.Http.EndPoints.EndPointPutIsActiveKiosk;
export const END_POINT_GET_CURRENT_TALON_NUMBER = config.KSEServer.Http.EndPoints.EndPointGetCurrentTalonNumber;
export const END_POINT_DELETE_SERVICE = config.KSEServer.Http.EndPoints.EndPointDeleteService;
export const END_POINT_DELETE_USER = config.KSEServer.Http.EndPoints.EndPointDeleteUser;
export const END_POINT_DELETE_BOARD = config.KSEServer.Http.EndPoints.EndPointDeleteBoard;
export const END_POINT_DELETE_KIOSK = config.KSEServer.Http.EndPoints.EndPointDeleteKiosk;
export const END_POINT_UPDATE_SERVICE = config.KSEServer.Http.EndPoints.EndPointUpdateService;
export const END_POINT_UPDATE_USER = config.KSEServer.Http.EndPoints.EndPointUpdateUser;
export const END_POINT_UPDATE_BOARD = config.KSEServer.Http.EndPoints.EndPointUpdateBoard;
export const END_POINT_UPDATE_KIOSK = config.KSEServer.Http.EndPoints.EndPointUpdateKiosk;
export const END_POINT_POST_SERVICE = config.KSEServer.Http.EndPoints.EndPointPostService;
export const END_POINT_POST_USER = config.KSEServer.Http.EndPoints.EndPointPostUser;
export const END_POINT_POST_BOARD = config.KSEServer.Http.EndPoints.EndPointPostBoard;
export const END_POINT_POST_KIOSK = config.KSEServer.Http.EndPoints.EndPointPostKiosk;
export const END_POINT_GET_QUEUE_INFO = config.KSEServer.Http.EndPoints.EndPointGetQueueInfo;
export const END_POINT_POST_PACKET = config.KSEServer.Http.EndPoints.EndPointPostPacket;
export const END_POINT_DELETE_PACKET = config.KSEServer.Http.EndPoints.EndPointDeletePacket;
export const END_POINT_UPDATE_PACKET = config.KSEServer.Http.EndPoints.EndPointUpdatePacket;
export const END_POINT_GET_TALON_INFO = config.KSEServer.Http.EndPoints.EndPointGetTalonInfo;
export const END_POINT_GET_PACKET = config.KSEServer.Http.EndPoints.EndPointGetPacket;
export const END_POINT_GET_PACKETS = config.KSEServer.Http.EndPoints.EndPointGetPackets;
export const END_POINT_GET_TALONS = config.KSEServer.Http.EndPoints.EndPointGetTalons;
export const END_POINT_GET_VIDEOS = config.KSEServer.Http.EndPoints.EndPointGetVideos;
export const END_POINT_PUT_VIDEOS = config.KSEServer.Http.EndPoints.EndPointPutVideos;
export const END_POINT_DELETE_VIDEO = config.KSEServer.Http.EndPoints.EndPointDeleteVideo;
export const END_POINT_GET_ORGANIZATION = config.KSEServer.Http.EndPoints.EndPointGetOrganization;
export const END_POINT_PUT_UPDATE_ORGANIZATION =config.KSEServer.Http.EndPoints.EndPointUpdateOrganization;
export const END_POINT_GET_ORGANIZATION_LOGO = config.KSEServer.Http.EndPoints.EndPointGetOrganizationLogo;
export const END_POINT_GET_SERVICES_BY_ORGANIZATION = config.KSEServer.Http.EndPoints.EndPointGetServicesByOrganization;
export const END_POINT_PUT_PACKET_SERVICE_STOP_LIST = config.KSEServer.Http.EndPoints.EndPointPutPacketServiceStopList;
export const END_POINT_PUT_PACKET_SERVICE_ACTIVE_LIST = config.KSEServer.Http.EndPoints.EndPointPutPacketServiceActiveList;
export const END_POINT_PUT_UPDATE_BOARD_VIDEO = config.KSEServer.Http.EndPoints.EndPointPutUpdateBoardVideo;
export const END_POINT_POST_CLIENT = config.KSEServer.Http.EndPoints.EndPointPostClientRegistration;
export const END_POINT_POST_ADD_ADMIN_CLIENT = config.KSEServer.Http.EndPoints.EndPointPostAdminClient;
export const END_POINT_POST_VERIFY_CODE = config.KSEServer.Http.EndPoints.EndPointPostClientRegistrationVerify;
export const END_POINT_POST_CLIENT_LOGIN = config.KSEServer.Http.EndPoints.EndPointPostClientLogin;
export const END_POINT_POST_RESET_PASSWORD = config.KSEServer.Http.EndPoints.EndPointPostResetPassword;
export const END_POINT_POST_UPDATE_PASSWORD = config.KSEServer.Http.EndPoints.EndPointPostUpdatePassword;
export const END_POINT_GET_CRITERION = config.KSEServer.Http.EndPoints.EndPointGetCriterion;
export const END_POINT_GET_CRITERIONS = config.KSEServer.Http.EndPoints.EndPointGetCriterionsList;
export const END_POINT_POST_CRITERION = config.KSEServer.Http.EndPoints.EndPointPostCriterion;
export const END_POINT_POST_CRITERION_UNIT = config.KSEServer.Http.EndPoints.EndPointPostCriterionUnit;
export const END_POINT_UPDATE_CRITERION = config.KSEServer.Http.EndPoints.EndPointUpdateCriterion;
export const END_POINT_UPDATE_CRITERION_UNIT = config.KSEServer.Http.EndPoints.EndPointUpdateCriterionUnit;
export const END_POINT_DELETE_CRITERION = config.KSEServer.Http.EndPoints.EndPointDeleteCriterion;
export const END_POINT_DELETE_CRITERION_UNIT = config.KSEServer.Http.EndPoints.EndPointDeleteCriterionUnit;
export const END_POINT_GET_COLORS_SCHEMA=config.KSEServer.Http.EndPoints.EndPointGetColorSchema;
export const END_POINT_UPDATE_COLORS_SCHEMA=config.KSEServer.Http.EndPoints.EndPointUpdateColorSchema;
export const END_POINT_QUEUE_GET=config.KSEServer.Http.EndPoints.EndPointGetQueue;
export const END_POINT_POST_RELOAD = config.KSEServer.Http.EndPoints.EndPointPostRebootData;
export const END_POINT_POST_SUPPORT_REQUEST = config.KSEServer.Http.EndPoints.EndPointPostSupportRequest;
export const END_POINT_GET_SUPPORT_REQUESTS = config.KSEServer.Http.EndPoints.EndPointGetSupportRequests;
export const END_POINT_GET_ORGANIZATIONS = config.KSEServer.Http.EndPoints.EndPointGetOrganizations;
export const END_POINT_GET_ALL_CLIENTS = config.KSEServer.Http.EndPoints.EndPointGetAllClients;
export const END_POINT_GET_SUPPORT_MESSAGES = config.KSEServer.Http.EndPoints.EndPointGetSupportMessages;
export const END_POINT_GET_QUEUE_INFO_COMPLEX = config.KSEServer.Http.EndPoints.EndPointGetQueueInfoComplex;
export const END_POINT_CREATE_TALONS_BY_RECORD = config.KSEServer.Http.EndPoints.EndPointCreateTalonsByRecord;
export const END_POINT_UPDATE_ACTIVE_TALON_BY_RECORD = config.KSEServer.Http.EndPoints.EndPointUpdateActiveTalonByRecord;