import {instance} from "./axiosConf";
import {
    END_POINT_CREATE_TALONS_BY_RECORD,
    END_POINT_DELETE_BOARD,
    END_POINT_DELETE_CRITERION,
    END_POINT_DELETE_CRITERION_UNIT,
    END_POINT_DELETE_KIOSK,
    END_POINT_DELETE_PACKET,
    END_POINT_DELETE_QUEUE,
    END_POINT_DELETE_QUEUE_COMPLEX,
    END_POINT_DELETE_SERVICE,
    END_POINT_DELETE_USER,
    END_POINT_DELETE_VIDEO, END_POINT_GET_ALL_CLIENTS,
    END_POINT_GET_BOARD,
    END_POINT_GET_CLIENTS,
    END_POINT_GET_COLORS_SCHEMA,
    END_POINT_GET_CRITERION,
    END_POINT_GET_CRITERIONS,
    END_POINT_GET_CURRENT_TALON_NUMBER,
    END_POINT_GET_KIOSK,
    END_POINT_GET_MINI_BOARD,
    END_POINT_GET_ORGANIZATION,
    END_POINT_GET_ORGANIZATION_LOGO, END_POINT_GET_ORGANIZATIONS,
    END_POINT_GET_PACKET,
    END_POINT_GET_PACKETS,
    END_POINT_GET_QUEUE,
    END_POINT_GET_QUEUE_COMPLEX,
    END_POINT_GET_QUEUE_LIST,
    END_POINT_GET_QUEUE_LIST_BY_QUEU,
    END_POINT_GET_SERVICES,
    END_POINT_GET_SERVICES_BY_ORGANIZATION, END_POINT_GET_SUPPORT_MESSAGES, END_POINT_GET_SUPPORT_REQUESTS,
    END_POINT_GET_TALON_INFO,
    END_POINT_GET_TALONS,
    END_POINT_GET_USERS,
    END_POINT_GET_VIDEOS,
    END_POINT_POST_ADD_ADMIN_CLIENT,
    END_POINT_POST_BOARD,
    END_POINT_POST_CLIENT,
    END_POINT_POST_CLIENT_LOGIN,
    END_POINT_POST_CRITERION,
    END_POINT_POST_CRITERION_UNIT,
    END_POINT_POST_KIOSK,
    END_POINT_POST_LOGO,
    END_POINT_POST_PACKET,
    END_POINT_POST_QUEUE,
    END_POINT_POST_QUEUE_COMPLEX,
    END_POINT_POST_RESET_PASSWORD,
    END_POINT_POST_SERVICE, END_POINT_POST_SUPPORT_REQUEST,
    END_POINT_POST_UPDATE_PASSWORD,
    END_POINT_POST_USER,
    END_POINT_POST_VERIFY_CODE,
    END_POINT_PUT_IS_ACTIVE_BOARD,
    END_POINT_PUT_IS_ACTIVE_KIOSK,
    END_POINT_PUT_IS_ACTIVE_USER,
    END_POINT_PUT_PACKET_SERVICE_ACTIVE_LIST,
    END_POINT_PUT_PACKET_SERVICE_STOP_LIST,
    END_POINT_PUT_UPDATE_BOARD_VIDEO,
    END_POINT_PUT_UPDATE_ORGANIZATION,
    END_POINT_PUT_VIDEOS, END_POINT_QUEUE_GET, END_POINT_UPDATE_ACTIVE_TALON_BY_RECORD,
    END_POINT_UPDATE_BOARD,
    END_POINT_UPDATE_COLORS_SCHEMA,
    END_POINT_UPDATE_CRITERION,
    END_POINT_UPDATE_CRITERION_UNIT,
    END_POINT_UPDATE_KIOSK,
    END_POINT_UPDATE_PACKET,
    END_POINT_UPDATE_QUEUE,
    END_POINT_UPDATE_QUEUE_COMPLEX,
    END_POINT_UPDATE_SERVICE,
    END_POINT_UPDATE_USER
} from "../constants/configConstants";
import {
    Board,
    BoardsUpdate, SupportRequest,
    Criterion,
    CriterionUnit,
    KioskAdd,
    Login,
    Logo,
    OrganizationUpdate,
    Packet,
    PasswordReset,
    QueueSend,
    QueueSendComplex,
    QueueUpdate,
    Service,
    User,
    UserActive,
    VerifyCode, GetOrganizationsResponse, GetAllUsersResponse, GetSupportMessagesResponse, newTalonByRecord
} from "../redux/types";
import {AxiosProgressEvent} from "axios";
import {Dispatch} from "@reduxjs/toolkit";
import {setVideosUploadedProgress} from "../redux/videosSlice";
import {Colors} from "../constants/styleConstants";

export const queueApi = {
    getQueue(organization_id:number) {
        return instance.get(`${END_POINT_GET_QUEUE}?organization_id=${organization_id}`);
    },
    getQueueList(organization_id:number) {
        return instance.get(`${END_POINT_GET_QUEUE_LIST}?organization_id=${organization_id}`);
    },
    getQueueListByQueu(queu_id:number) {
        return instance.get(`${END_POINT_GET_QUEUE_LIST_BY_QUEU}?queue_id=${queu_id}`);
    },
    putUpdateBoardVideo(data: BoardsUpdate) {
        return instance.put(`${END_POINT_PUT_UPDATE_BOARD_VIDEO}`, data);
    },
    postQueue(data: QueueSend) {
        return instance.post(`${END_POINT_POST_QUEUE}`, data);
    },
    postUpdateLogo(data: Logo) {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
              },
        }
        return instance.post(`${END_POINT_POST_LOGO}`, data, config);
    },
    putUpdateOrganisation(data:OrganizationUpdate) {
        return instance.put(`${END_POINT_PUT_UPDATE_ORGANIZATION}`,data)
    },
    deleteQueue(id: number) {
        return instance.delete(`${END_POINT_DELETE_QUEUE}?id=${id}`,);
    },
    deleteVideo(id: number) {
        return instance.delete(`${END_POINT_DELETE_VIDEO}?id=${id}`,);
    },
    getQueueComplex(organization_id:number) {
        return instance.get(`${END_POINT_GET_QUEUE_COMPLEX}?organization_id=${organization_id}`);
    },
    postQueueComplex(data: QueueSendComplex) {
        return instance.post(`${END_POINT_POST_QUEUE_COMPLEX}`, data);
    },
    deleteQueueComplex(id: number) {
        return instance.delete(`${END_POINT_DELETE_QUEUE_COMPLEX}?id=${id}`,);
    },
    getServices(id: number) {
        return instance.get(`${END_POINT_GET_SERVICES}?queue_id=${id}`,);
    },
    getUsers(id: number) {
        return instance.get(`${END_POINT_GET_USERS}?id=${id}`);
    },
    getClietnsList(id: number) {
        return instance.get(`${END_POINT_GET_CLIENTS}?organization_id=${id}`);
    },
    putIsActiveUser(id: number, isActive: boolean) {
        return instance.put(`${END_POINT_PUT_IS_ACTIVE_USER}`, {id: id, isActive: isActive});
    },
    getBoard(queueId: number, boardId: number) {
        return instance.get(`${END_POINT_GET_BOARD}?queue_id=${queueId}&board_id=${boardId}`);
    },
    putIsActiveBoard(id: number, isActive: boolean) {
        return instance.put(`${END_POINT_PUT_IS_ACTIVE_BOARD}`, {id: id, isActive: isActive});
    },
    getMiniBoard(queueId: number, userId: number) {
        return instance.get(`${END_POINT_GET_MINI_BOARD}?queue_id=${queueId}&user_id=${userId}`);
    },
    getKiosk(queueId: number, kioskId: number) {
        return instance.get(`${END_POINT_GET_KIOSK}?queue_id=${queueId}&kiosk_id=${kioskId}`);
    },
    putIsActiveKiosk(id: number, isActive: boolean) {
        return instance.put(`${END_POINT_PUT_IS_ACTIVE_KIOSK}`, {id: id, isActive: isActive});
    },
    getCurrentTalon(queueId: number, serviceId: number, packetId: number, letter: string, is_pay: boolean, create_time: string, selectCriterionUnit:number, is_random_number:boolean) {
        return instance.post(`${END_POINT_GET_CURRENT_TALON_NUMBER}`, {
            queue_id: queueId,
            service_id: serviceId,
            packet_id: packetId,
            letter: letter,
            is_window: false,
            create_time: create_time,
            status_id: 1,
            place_call: '',
            is_pay: is_pay,
            criterion_id: selectCriterionUnit,
            is_random_number: is_random_number
        });
    },
    deleteService(id: number) {
        return instance.delete(`${END_POINT_DELETE_SERVICE}?id=${id}`,);
    },
    deleteUser(id: number) {
        return instance.delete(`${END_POINT_DELETE_USER}?id=${id}`,);
    },
    deleteBoard(id: number) {
        return instance.delete(`${END_POINT_DELETE_BOARD}?id=${id}`,);
    },
    deletePacket(id: number) {
        return instance.delete(`${END_POINT_DELETE_PACKET}?id=${id}`,);
    },
    deleteCriterion(id: number) {
        return instance.delete(`${END_POINT_DELETE_CRITERION}?id=${id}`,);
    },
    deleteCriterionUnit(id: number) {
        return instance.delete(`${END_POINT_DELETE_CRITERION_UNIT}?id=${id}`,);
    },
    deleteKiosk(id: number) {
        return instance.delete(`${END_POINT_DELETE_KIOSK}?id=${id}`,);
    },
    postService(data: Service, queue_id: number) {
        return instance.post(`${END_POINT_POST_SERVICE}`, {...data, depend_service:data.depend_service.join(','), queue_id: queue_id});
    },
    postUser(data: User, queue_id: number) {
        return instance.post(`${END_POINT_POST_USER}`, {
            ...{...data,
                services: data.services.join(','),
                services_for_add: data.services_for_add.join(','),
                packets_transfer: data.packets_transfer.join(','),
                return_services: data.return_services.join(',')},
            queue_id: queue_id
        });
    },
    postBoard(data: Board, queue_id: number) {
        return instance.post(`${END_POINT_POST_BOARD}`, {
            ...{...data, services: data.services.join(',')},
            queue_id: queue_id
        });
    },
    postPacket(data: Packet, queue_id: number) {
        return instance.post(`${END_POINT_POST_PACKET}`, {
            ...{...data, services: data.services.join(',')},
            queue_id: queue_id
        });
    },
    postCriterion(data: Criterion, queue_id: number) {
        return instance.post(`${END_POINT_POST_CRITERION}`, {
            ...data,
            queue_id: queue_id
        });
    },
    postCriterionUnit(data: CriterionUnit) {
        return instance.post(`${END_POINT_POST_CRITERION_UNIT}`, {...data});
    },
    postKiosk(data: KioskAdd, queue_id: number) {
        return instance.post(`${END_POINT_POST_KIOSK}`, {
            ...{...data, services: data.services.join(','), complex:JSON.stringify(data.complex)},
            queue_id: queue_id
        });
    },
    putQueue(data: QueueUpdate) {
        return instance.put(`${END_POINT_UPDATE_QUEUE}`, data);
    },
    putQueueComplex(data: QueueUpdate) {
        return instance.put(`${END_POINT_UPDATE_QUEUE_COMPLEX}`, data);
    },
    putService(data: Service) {
        return instance.put(`${END_POINT_UPDATE_SERVICE}`, {...data,
            depend_service:data.depend_service
                ? data.depend_service.join(',')
                : ''});
    },
    putUser(data: User) {
        return instance.put(`${END_POINT_UPDATE_USER}`, {
            ...data,
            services: Array.isArray(data.services) ? data.services.join(',') : data.services,
            services_for_add: Array.isArray(data.services_for_add) ? data.services_for_add.join(',') : data.services_for_add,
            packets_transfer: Array.isArray(data.packets_transfer) ? data.packets_transfer.join(',') : data.packets_transfer,
            return_services: Array.isArray(data.return_services) ? data.return_services.join(',') : data.return_services
        });
    },
    putBoard(data: Board) {
        return instance.put(`${END_POINT_UPDATE_BOARD}`, {
            ...data,
            services: Array.isArray(data.services) ? data.services.join(',') : data.services
        });
    },
    putPacket(data: Packet) {
        return instance.put(`${END_POINT_UPDATE_PACKET}`, {
            ...data,
            services: Array.isArray(data.services) ? data.services.join(',') : data.services
        });
    },
    putCriterion(data: Criterion) {
        return instance.put(`${END_POINT_UPDATE_CRITERION}`, {
            ...data});
    },
    putCriterionUnit(data: CriterionUnit) {
        return instance.put(`${END_POINT_UPDATE_CRITERION_UNIT}`, {
            ...data});
    },
    putKiosk(data: KioskAdd) {
        return instance.put(`${END_POINT_UPDATE_KIOSK}`, {
            ...data,
            complex:JSON.stringify(data.complex),
            services: Array.isArray(data.services) ? data.services.join(',') : data.services
        });
    },
    getTalonInfo(id: number) {
        return instance.get(`${END_POINT_GET_TALON_INFO}?id=${id}`);
    },
    getPacket(packet_id: number) {
        return instance.get(`${END_POINT_GET_PACKET}?packet_id=${packet_id}`);
    },
    getPacketsList(organization_id: number | undefined, is_complex: number, queue_id:number | undefined = undefined) {
        return instance.get(`${END_POINT_GET_PACKETS}?organization_id=${organization_id}&is_complex=${is_complex}&queue_id=${queue_id}`);
    },
    getTalons(organization_id: number) {
        return instance.get(`${END_POINT_GET_TALONS}?organization_id=${organization_id}`)
    },
    getServicesByOrganization(id: number) {
        return instance.get(`${END_POINT_GET_SERVICES_BY_ORGANIZATION}?organization_id=${id}`,);
    },
    getOrganization(organization_id: number) {
        return instance.get(`${END_POINT_GET_ORGANIZATION}?organization_id=${organization_id}`);
    },
    getOrganizationLogo(organization_id: number) {
        return instance.get(`${END_POINT_GET_ORGANIZATION_LOGO}?organization_id=${organization_id}`);
    },
    getOrganizationVideos(organization_id: number) {
        return instance.get(`${END_POINT_GET_VIDEOS}?organization_id=${organization_id}`);
    },
    postOrganizationVideos(file: File, organization_id: number, title: string, dispatch: Dispatch, id: string) {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "x-rapidapi-host": "file-upload8.p.rapidapi.com",
                "x-rapidapi-key": "your-rapidapi-key-here",
              },
              onUploadProgress (progressEvent: AxiosProgressEvent) {
                const uploadProgress = `${Math.round(progressEvent.loaded / file.size * 100)}`
                dispatch(setVideosUploadedProgress({id, progress: uploadProgress, size: file.size}))
              }
        }
        return instance.post(`${END_POINT_PUT_VIDEOS}`, {
            file, 
            organization_id, 
            title
        },
        config
        );
    },
    putPacketServiceStopList(packet_id:number, internal_id: string) {
        return instance.put(`${END_POINT_PUT_PACKET_SERVICE_STOP_LIST}`, {
            id:packet_id,
            internal_id:internal_id
        });
    },
    putPacketServiceActiveList(packet_id:number, internal_id: string) {
        return instance.put(`${END_POINT_PUT_PACKET_SERVICE_ACTIVE_LIST}`, {
            id:packet_id,
            internal_id:internal_id
        });
    },
    postClientRegistration(data:UserActive) {
        return instance.post(`${END_POINT_POST_CLIENT}`, {...data});
    },
    postAddAdminClient(data:UserActive) {
        return instance.post(`${END_POINT_POST_ADD_ADMIN_CLIENT}`, {...data});
    },
    postClientVerifyCode(data:VerifyCode) {
        return instance.post(`${END_POINT_POST_VERIFY_CODE}`, {...data});
    },
    postClientLogin(data:Login) {
        return instance.post(`${END_POINT_POST_CLIENT_LOGIN}`, {...data});
    },
    postClientResetPassword(data:PasswordReset) {
        return instance.post(`${END_POINT_POST_RESET_PASSWORD}`, {...data});
    },
    postClientUpdatePassword(data:PasswordReset) {
        return instance.post(`${END_POINT_POST_UPDATE_PASSWORD}`, {...data});
    },
    getCriterion(criterion_id: number) {
        return instance.get(`${END_POINT_GET_CRITERION}?criterion_id=${criterion_id}`);
    },
    getCriterionsList( queue_id:number | undefined = undefined) {
        return instance.get(`${END_POINT_GET_CRITERIONS}?queue_id=${queue_id}`);
    },
    getColorSchema(id: number) {
        return instance.get(`${END_POINT_GET_COLORS_SCHEMA}?organization_id=${id}`);
    },
    getQueueShort(id: number) {
        return instance.get(`${END_POINT_QUEUE_GET}?id=${id}`);
    },
    putColorSchema(data:Colors) {
        return instance.put(`${END_POINT_UPDATE_COLORS_SCHEMA}`, {...data})
    },
    postSupportRequest(data: SupportRequest) {
        return instance.post(`${END_POINT_POST_SUPPORT_REQUEST}`, data);
    },
    getSupportRequests(organizationID: number | undefined) {
        return instance.get(`${END_POINT_GET_SUPPORT_REQUESTS}?organization_id=${organizationID}`);
    },
    getOrganizations() {
        return instance.get<GetOrganizationsResponse>(`${END_POINT_GET_ORGANIZATIONS}`)
    },
    getAllClients() {
        return instance.get<GetAllUsersResponse>(`${END_POINT_GET_ALL_CLIENTS}`);
    },
    getSupportMessages() {
        return instance.get<GetSupportMessagesResponse>(`${END_POINT_GET_SUPPORT_MESSAGES}`)
    },
    createTalonsByRecord(list:newTalonByRecord[]) {
        return instance.post<GetSupportMessagesResponse>(`${END_POINT_CREATE_TALONS_BY_RECORD}`, {list:list})
    },
    putActiveTalonByRecord(fio: string, year_birth: number) {
        return instance.post<GetSupportMessagesResponse>(`${END_POINT_UPDATE_ACTIVE_TALON_BY_RECORD}`, {is_active: true, fio: fio, year_birth:year_birth})
    }
}
