import {createSlice, Dispatch} from '@reduxjs/toolkit'
import {PayloadAction} from '@reduxjs/toolkit'
import {queueApi} from "../api/queue-api";
import {
    CurrentTicketNumber,
    Kiosk,
    KioskState, newTalonByRecord,
} from "./types";
import {showNotification, showNotificationInfo, showNotificationSuccess} from "../utils/notification";
import {kioskApi} from "../api/ws/kiosk-api";
import {setRebootData} from "./rebootSlice";
import {InfoReboot} from "../api/reboot-api";

const initialState: KioskState = {
    kiosk: {
        kiosk: null,
        services: []
    },
    currentTicketNumber:null,
    isLoading:false,
    error: false,
    errorCount: 0 ,
}

export const kioskSlice = createSlice({
    name: 'kiosk',
    initialState,
    reducers: {
        setKiosk: (state, action: PayloadAction<Kiosk>) => {
            state.kiosk = action.payload
        },
        setCurrentTalonNumber: (state, action: PayloadAction<CurrentTicketNumber>) => {
            state.currentTicketNumber = action.payload
        },
        deleteCurrentTalonNumber: (state) => {
            state.currentTicketNumber = null
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload
        },
        setErrorCount: (state, action: PayloadAction<number>) => {
            state.errorCount = action.payload
        }
    },
})

export const {setKiosk, setCurrentTalonNumber, deleteCurrentTalonNumber, setIsLoading,setError
    ,setErrorCount} = kioskSlice.actions

export default kioskSlice.reducer

export const getKioskTC = (queueId: number, kioskId: number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getKiosk(queueId, kioskId);
        if (result.status === 200) {
            dispatch(setKiosk(result.data.data))
        } else {
            showNotification('Не верные данные, проверьте запрос')
        }
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const setIsActiveKioskTC = (id: number, isActive: boolean) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.putIsActiveKiosk(id, isActive);
        if (result.status === 200) {
        } else {
            showNotification('Не верные данные, проверьте запрос')
        }
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const getCurrentTalonNumberTC = (queueId:number, serviceId:number, packetId:number, letter:string, is_pay:boolean, create_time:string, selectCriterionUnit:number = 0, is_random_number:boolean = false) => async (dispatch: Dispatch) => {
   dispatch(setIsLoading(true))
    try {
        let result = await queueApi.getCurrentTalon(queueId, serviceId, packetId, letter, is_pay, create_time, selectCriterionUnit, is_random_number);
        if (result.status === 200) {
            dispatch(setIsLoading(false))
            dispatch(setCurrentTalonNumber(result.data.data))
        } else {
            dispatch(setIsLoading(false))
            showNotification('Не верные данные, проверьте запрос')
        }
    } catch (error: any) {
        dispatch(setIsLoading(false))
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const createKioskConnectionTC = (queueId: number, services: string) => (dispatch: Dispatch) =>{
    try{
        kioskApi.createConnection()
        kioskApi.subscribe(() => {
            dispatch(setError(true))
            dispatch(setErrorCount(1))
        }, () => {
            dispatch(setError(false))
        }
        , (data: InfoReboot) => {
            dispatch(setRebootData(data))
        })
    }
    catch (error: any) {
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}

export const destroyConnectionKioskTC = () => (dispatch: Dispatch) => {
    try {
        kioskApi.destroyConnection()
    } catch (error: any) {
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}

export const deleteCurrentTalonNumberTC = () => async (dispatch: Dispatch) => {
    dispatch(deleteCurrentTalonNumber())
}

export const createTalonsByRecordTC = (list :newTalonByRecord[]) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
        let result = await queueApi.createTalonsByRecord(list);
        if (result.status === 200) {
            showNotificationSuccess('Список успешно загружен')
        } else {
            showNotificationInfo('Не верные данные, проверьте запрос')
        }
    } catch (error: any) {
        dispatch(setIsLoading(false))
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}